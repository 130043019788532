.sidebar {
    flex: 1;
    height: calc(100vh - 50px);
    background-color: rgb(251, 251, 255);
    position: sticky;
    top: 50px;
}

.sidebarWrapper {
    padding: 20px;
    color: #555;
}

.sidebarMenu {
    margin-bottom: 10px;
}

.sidebarTitle {
    font-size: 13px;
    color: rgb(126, 117, 117);
    font-weight: 700;
}

.sidebarList {
    list-style: none;
    padding: 5px;
}

.sidebarListItem {
    padding: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    border-radius: 10px;
}

.sidebarListItem.active,
.sidebarListItem:hover {
    background-color: rgb(240, 240, 255);
    color: #bebeff;
}

.sidebarIcon {
    margin-right: 5px;
    font-size: 20px !important;
    color: #dad9dc;

}

ul.sidebarList {
    font-size: small;
    color: #b1ada6;
}

li#analytics {
    color: #a1a8a9;
}

.sweet-loading-sidebar {
    margin: auto !important;
    padding-left: 40% !important;
    padding-top: 50% !important;
}
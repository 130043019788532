
.main-container {
    display: flex;
}

.others {
    flex: 4;
}

.sec-container {
    display: flex;
}

.w-100 {
    flex: 6;
    margin: auto;
    align-items: center;
    position: relative;
}

body{
 //overflow: hidden !important;
}
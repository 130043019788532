.widgetLg {
    flex: 2;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    padding: 20px;
}

.widgetLgTitle {
    font-size: 24px;
    font-weight: 600;
    font-family: math, sans-serif;
}

.widgetLgTitle span {
    font-size: 10px;
    font-weight: 600;
}

.widgetLgTable{
    width: 100%;
    border-spacing: 20px;
}

.widgetLgTh{
    text-align: left;
}

.widgetLgUser{
    display: flex;
    align-items: center;
    font-weight: 600;
}

.widgetLgImg {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
}

.widgetLgDate,.widgetLgAmount{
    font-family: math, sans-serif;
    font-weight: 100;
    font-size: 14px;
}

.widgetLgButton {
    padding: 5px 7px;
    border: none;
    border-radius: 10px;
}

.widgetLgButton.Approved{
    background-color: #e5faf2;
    color: #3bb077;
}
.widgetLgButton.Declined{
    background-color: #fff0f1;
    color: #d95087;

}

.widgetLgButton.Win{
    background-color: #e5faf2;
    color: #3bb077;
}
.widgetLgButton.Loss{
    background-color: #fff0f1;
    color: #d95087;

}
.widgetLgButton.Pending{
    background-color: #ebf1fe;
    color: #2a7ade;

}

span.widgetLgName {
    font-family: math;
}
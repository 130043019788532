.OperatorMonthlyProfitabilityChart {
    font-family: sans-serif;
    text-align: center;
}

.operator_monthly_profibility {
    /* align-items: stretch; */
    text-align: -webkit-center;
    margin-top: 20px;
    margin-left: 50px;
    flex: 2 1;
    box-shadow: 0px 0px 15px -10px rgb(0, 0, 0.75);
}

.CustomShapChartMonthlyProfitability {
    font-family: sans-serif;
    text-align: center;

}

.operator_monthly_containned {
    margin-top: 20px;
    margin-left: 50px;
    flex: 2 1;
    box-shadow: 0px 0px 15px -10px rgb(0, 0, 0.75);
    width: 80%;
    max-width: 100%;
}

.chartTitle_operatorAnalytics {
    width: 100%;
    padding-left: 30px;
    padding-top: 15px;
    font-family: math, sans-serif;
    font-weight: bold;
    font-size: 12px;
}

.multicolor-bar {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.multicolor-bar .values .value {
    float: left;
    text-align: center;
}

.multicolor-bar .scale .graduation {
    float: left;
    text-align: center;
}

.multicolor-bar .bars .bar {
    float: left;
    height: 10px;
}

.multicolor-bar .bars div.bar:first-of-type {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.multicolor-bar .bars div.bar:last-of-type {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.multicolor-bar .legends {
    text-align: center;
    font-size: 12px;
    color: #afafae;
}


.multicolor-bar .legends .legend {
    display: inline-block;
    margin: 0 5px;
    text-align: center;
}

.multicolor-bar .legends .legend .dot {
    font-size: 25px;
    vertical-align: middle;
}

.multicolor-bar .legends .legend .label {
    margin-left: 2px;
    vertical-align: middle;
}

.values .scale .bars {
    margin-left: 20px !important;
}

span.MuiTypography-body1 {
    width: max-content;
    /*
    color: yellowgreen;
    */
}

.DrawDownChartMonthly {
    font-family: sans-serif;
    text-align: center;
}

.recharts-wrapper.operator_monthly_profibility {
    /*
    width: 80% !important;
    */
}

.operator_monthly_profibility.pane_container_settings {
    height: 420px;
}

.barchartProfibility {
    max-height: 43px;
}

.sweet-loading-operatorAnalysis {
    margin: 38%;
    margin-top: 20%;
}


.super-app-theme--cell{
    text-align: center;
    font-size: 12px;
}

g.text-group text {
    font-family: -webkit-body;
    font-size: small !important;
}
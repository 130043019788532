/*
 * react-calendar-heatmap styles
 *
 * All of the styles in this file are optional and configurable!
 * The github and gitlab color scales are provided for reference.
 */
* > svg:not(.svg-inline--fa.fa-align-left.fa-w-14.super-crazy-colors, .MuiSvgIcon-root.featuredIcon.negative) {
    fill: #a2ffb3;
}

* a {
    color: #555555 !important;
    text-decoration: none !important;
}

.react-calendar-heatmap text {
    font-size: 10px;
    fill: #1e1daa;
}

.react-calendar-heatmap .react-calendar-heatmap-small-text {
    font-size: 5px;
}

.react-calendar-heatmap rect:hover {
    stroke: #555;
    stroke-width: 1px;
}

/*
 * Default color scale
 */

.react-calendar-heatmap .color-empty {
    fill: white;
}

.react-calendar-heatmap .color-filled {
    fill: #c61aab;
}

/*
 * Github color scale
 */

.react-calendar-heatmap .color-github-0 {
    fill: #ee9b3b;
}
.react-calendar-heatmap .color-github-1 {
    fill: #e6ba1e;
}
.react-calendar-heatmap .color-github-2 {
    fill: #8cc665;
    text-align: center;
    content: "Hello";
}

.react-calendar-heatmap .color-github-3 {
    fill: #8fff9054;
}


.react-calendar-heatmap .color-github-4 {
    fill: #681210;
}

.react-calendar-heatmap .color-github-win {
    fill: #9fff9e;
}

.react-calendar-heatmap .color-github-loss {
    fill: #f96248;
}


.react-calendar-heatmap .color-github-others{
    fill: #ecf2ff;
}




/*
 * Gitlab color scale
 */

.react-calendar-heatmap .color-gitlab-0 {
    fill: #ed6b55;
}
.react-calendar-heatmap .color-gitlab-1 {
    fill: #acd5f2;
}
.react-calendar-heatmap .color-gitlab-2 {
    fill: #7fa8d1;
}
.react-calendar-heatmap .color-gitlab-3 {
    fill: #49729b;
}
.react-calendar-heatmap .color-gitlab-4 {
    fill: #254e77;
}


text.react-calendar-heatmap-small-text.react-calendar-heatmap-weekday-label{
    font-size: 2px;
    font-weight: bold;
     color: #9fff9e !important;
}
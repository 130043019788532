.searchInputs {
    margin-top: 15px;
    display: flex;
    font-family: source-code-pro, serif;
}

.search input {
    background-color: white;

    border-radius: 6px;
    border-top-color: #eeeeee;
    border-bottom-color: #eeeeee;
    border-right-color: #eeeeee;
    border-left-color: #eeeeee;
    font-size: 14px;
    font-style: italic;
    padding: 15px;
    height: 30px;
    width: 300px;
    text-align: center;
}

.searchIcon {
    height: 30px;
    width: 10px;
    background-color: white;
    display: grid;
    place-items: center;
}

input:focus {
    outline: none;
}

.searchIcon svg {
    font-size: 35px;
}

.dataResult {
    margin-top: 5px;
    width: 300px;
    height: 200px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    overflow-y: scroll;
    scrollbar-color: yellow blue;
    position: absolute;
    z-index: 99999999;
}

.dataResult::-webkit-scrollbar {
    width: 10px;
    display: block;
    height: 10px;
    color: black;
    scrollbar-color: #87ceeb #ff5621;
}

.dataResult .dataItem {
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    color: black;
    justify-content: center;
    margin: inherit;
    align-items: baseline;
}
.dataItem p {
    margin-left: 10px;
}

a {
    text-decoration: none;
}

a:hover {
    background-color: lightgrey;
}

#clearBtn {
    cursor: pointer;
}

p.inner_text {
    font-size: 13px;
    font-style: italic;
    color: #0000ffad;
}

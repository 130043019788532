.home {
    flex: 4;
}

.homeWidgets {
    display: flex;
    margin: 20px;
}

.profile-dashboard {
    flex: 6 4;
    justify-content: center;
}
.profile-dashboard-home{
    flex: 6 4;
    justify-content: center;
}
.btn-link{
    text-decoration: none!important;

}
/*
.profile-dashboard div.card {
    padding: 50px;
    margin-right: 500px;
    margin-left: 500px;
}
*/

.main-profile {
    margin: auto;
    max-width: max-content;
}

.profile-title-label {
    font-weight: bold;
}

.MuiToolbar-root.MuiToolbar-regular.MuiToolbar-gutters {
    background: rgb(240, 247, 249);
    background: linear-gradient(90deg, rgba(240, 247, 249, 1) 28%, rgba(233, 249, 255, 0.969625350140056) 50%, rgba(237, 249, 255, 1) 81%, rgba(229, 255, 224, 1) 99%);
    justify-content: space-between !important;
    font-family: sans-serif;
    font-weight: bold;
}

a.link {
    font-size: small;
    font-family: 'FontAwesome' !important;
    font-weight: initial !important;
    color: #a3a198 !important;
}

.MuiToolbar-root.MuiToolbar-regular.MuiToolbar-gutters {
    background: linear-gradient(
            90deg, #f0f7f9 28%, rgba(233, 249, 255, .969625350140056) 50%, #edf9ff 81%, #e5ffe0 99%) !important;
}

a.logButt {
    color: white !important;
}


.operator_adding_sector {
    width: auto;
    overflow: hidden;
    min-height: 80vh;
}

.section1_add_operators {
    height: 200px;
    margin: auto;
}

.header_sector_text {
    margin: auto;
    text-align: center;


}

.textfield_sector {
    align-content: center;
}

.operators_input_sector1 {
    margin: auto;
    text-align: center;
    padding-top: 25px;
}

.errorMessage_text {
    text-align: center;
    font-size: small;
    padding-top: 20px;

}

.delete_operators_text {
    text-align: center;
    padding: 25px;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    color: black;
}

.MuiTypography-displayBlock {
    display: block;
    font-size: small;
}


.successfully_message {
    width: max-content;
    font-size: medium;
    color: #41c74d;
}

span.modal_close_button_text {
    font-size: medium;
}

.operators_settings {
    height: auto;
    overflow: hidden;
    text-align: center;
    padding: 20px;
}

.operators_error_text {
    font-size: smaller;
}

.updateprofile-modal {
    top: 50%;
    left: 50%;
    width: 400px;
    border: 2px
    solid rgb(0, 0, 0);
    padding: 32px;
    position: absolute;
    transform: translate(-50%, -50%);
    background-color: rgb(255, 255, 255);
    height: 100%;
    overflow-y: scroll;
}

span.MuiTypography-body1 {
    width: max-content;
}


label.MuiFormControlLabel-root.pendingOrderForm.MuiFormControlLabel-labelPlacementTop {
    /* margin-left: 55px; */
    align-items: self-end;
}

.history_table_header {
    font-size: 12px !important;
    font-weight: bold !important;
    font-family: sans-serif, "Open Sans";
}

.histtory_table_body {
    font-family: sans-serif, "Open Sans";
    font-size: 11px;
    color: darkblue !important;
}

table.MuiTable-root.css-rqglhn-MuiTable-root {
    background: aliceblue;
}

.sweet-loading.home {
    margin: auto;
    padding-left: 40%;
}

.sweet-loading.analytics {
    margin: 50vh;
}

span.MuiTypography-root.MuiListItemText-primary.MuiTypography-body1.MuiTypography-displayBlock {
    font-size: small;
}

span.MuiTypography-root.MuiListItemText-primary.MuiTypography-body2.MuiTypography-displayBlock {
    font-size: small;
}
.sweet-loading.component-loader{
    margin: 50vh;
}

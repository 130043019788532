@import url('https://fonts.googleapis.com/css2?family=Megrim&display=swap');

.MuiTypography-body1 {
    font-size: small;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 700;
    line-height: 1.5;
    letter-spacing: 0.00938em;
}

.terms_container_modal {
    z-index: 99999999999999999999999999;
}

h1.disclamer_topHeader {
    font-weight: bold;
}


label.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiInputLabel-standard.MuiFormLabel-root.MuiFormLabel-colorPrimary.css-1c2i806-MuiFormLabel-root-MuiInputLabel-root {
    font-size: large;
}

label#input-with-icon-textfield-label {
    font-size: large;
}

label#fullWidth-label {
    font-size: small;
}

section.container.feedbacksection {
    padding: inherit;
    background: aliceblue;
}

h2.feedback_title {
    text-align: center;
    font-size: xx-large;
    font-weight: 800;
}

p.feedback_sub_header {
    text-align: center;
    font-size: larger;
    font-family: 'Nunito', sans-serif;
    font-weight: 500;
    padding: 0px;
}

.MuiBox-root.css-1gfupln {
    display: flex;
    justify-content: center;
}

p.feedback_sub_header {
    margin: 25px;
}

.error_success {
    padding: 40px;
    font-size: x-smaller;
    font-size: smaller;
}
.MuiToolbar-root.MuiToolbar-regular.login.MuiToolbar-gutters {
    justify-content: center !important;
}

h2.text-center.mb-4.login {
    font-weight: 900;
    margin: 10px;
    font-family: "Wallpoet", sans-serif;
    color: dimgray;
}


/* ==========================================================================
Sign up form
========================================================================== */
.sign-up .signup-form .sign-up-btn {
    padding: 15px 0;
    border-radius: 3px;
    width: 80%;
    font-size: 13px;
}

.sign-up .signup-form .form-input-group {
    width: 80%;
    height: 55px;
    margin: 0 auto 10px;
    border-radius: 5px;
    border: solid 1px #E6E9EA;
    text-align: left;
    position: relative;
}

.sign-up .signup-form .form-input-group i {
    color: #FF5274;
    font-size: 14px;
}

.sign-up .signup-form .form-input-group i:after {
    content: "";
    height: 30px;
    width: 1px;
    border-right: solid 1px #E6E9EA;
    position: absolute;
    top: 50%;
    left: 30px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.sign-up .signup-form .form-input-group i.fa-lock {
    font-size: 18px;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    margin-left: 20px;
}

.sign-up .signup-form .form-input-group i.fa-envelope {
    font-size: 14px;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    margin-left: 20px;
}

.sign-up .signup-form .form-input-group input {
    padding-left: 68px;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    border: none;
}

.sign-up .signup-form .form-input-group input::-webkit-input-placeholder {
    color: #BCC1C3;
}

.sign-up .signup-form .form-input-group input:-moz-placeholder {
    color: #BCC1C3;
}

.sign-up .signup-form .form-input-group input::-moz-placeholder {
    color: #BCC1C3;
}

.sign-up .signup-form .form-input-group input:-ms-input-placeholder {
    color: #BCC1C3;
}


.envelopeLogin {
    color: red;
    padding: 10px;
}

.loginButton {
    padding: 20px !important;
    background-color: #D7405D !important;
}

.MuiToolbar-root.MuiToolbar-regular.login.MuiToolbar-gutters{
    background: black !important;
}

.loginClassbody {
    margin-top: 50%;
}

h2.text-center.mb-4.login.reset {
    font-size: x-large;
}
.userList {
    flex: 4;
    overflow: overlay;
}

.userListUser {
    display: flex;
    align-items: center;
}

.userListImg {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
}

.userListEdit{
    border: none;
    border-radius: 10px;
    padding: 5px 10px;
    background-color: #3bb077;
    color: white;
    cursor: pointer;
    margin-right: 20px;
}

.userListDelete{
    color: red;
    cursor: pointer;
}

li.MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiListItem-gutters.MuiListItem-button {
    font-size: smaller;
}

.MuiDataGrid-columnHeaderTitle {
    font-weight: 600 !important;
}

.sweet-loading.transactions {
    margin: 38%;
    margin-top: 20%;
}

Transactions {
    overflow: visible !important;
}
.chart {
    margin: 20px;
    padding: 20px;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
}

.chartTitle {
    margin-bottom: 20px;
    font-size: 20px;
    font-family: math, sans-serif;
    font-weight: 700;
;
    font-size: 20px;
    font-family: math;
    font-weight: 700;
}

.chartTitle span{
    font-size: 10px;
    font-weight: 600;
}
.chart-Container{
    display: flex;
}
.pie-Container{
}

.pie-Container canvas{
    align-content: center;
}

.inner-container{
    padding-top: 30px;
    margin-left: 110px;
}
.heatmapcalender-Container{
    display: flex;
    margin: 25%;
    margin-top: auto;
    margin-bottom: auto;

}

.color-empty{
    fill: #eeeeee;
}

path.recharts-curve.recharts-line-curve{
    fill: #9fff9e2e !important;
}

circle.recharts-dot.recharts-line-dot{
    fill: #5bff44;
}

text.recharts-text.recharts-cartesian-axis-tick-value {
    font-size: 15px;
    fill: #b3f7c8;
}
h1 {
    font-family: "Roboto";
    text-align: center;
    margin-bottom: 40px;
}

.timer-wrapper {
    display: flex;
    justify-content: center;
}

.time-wrapper {
    position: relative;
    width: 80px;
    height: 60px;
    font-size: 48px;
    font-family: "Montserrat";
}

.time-wrapper .time {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateY(0);
    opacity: 1;
    transition: all 0.2s;
}

.time-wrapper .time.up {
    opacity: 0;
    transform: translateY(-100%);
}

.time-wrapper .time.down {
    opacity: 0;
    transform: translateY(100%);
}

.modal-body {
    align-self: center;
    text-align: center;
}
.modal-body span {
    color: #ff9e7c;
    font-size: 12px;
}

.modal-body div svg path {
    fill: #fbfffb !important;
}

.MuiToolbar-root.MuiToolbar-regular.MuiToolbar-gutters {
    justify-content: right;
}

.modal-header {
    background: rgb(239, 255, 222);
    background: linear-gradient(90deg, rgba(239, 255, 222, 0.9668242296918768) 10%, rgba(220, 252, 198, 1) 50%, rgba(67, 208, 93, 1) 75%, rgba(178, 229, 240, 1) 100%);
}
.modal-title {
    margin-bottom: 0;
    line-height: 1.5;
    font-size: medium;
    color: red;
    font-family: math, sans-serif;
    font-weight: 600;
}

button.close {
    box-sizing: content-box;
    width: 1em;
    height: 1em;
    padding: 0.25em;
    color: #000;
    background: transparent;
    border: 0;
    border-radius: 0.25rem !important;
    opacity: .5;
}
@media screen and (max-width:1200px) {
	header {
		width: 90%;
		margin: 0 auto;
	}

	.intro-content {
		display: inline-block;
		width: 70%;
	}

	.hero .hero-content {
		padding-top: 28%;
	}

}

@media screen and (max-width:1024px) {
}

/* Responsive nav kicks in */
@media screen and (max-width:991px) {
	header {
		width: 90%;
		padding-left: 25px;
	}

	.hero .hero-content {
		padding-top: 32%;
	}

	.header-nav {
		position: fixed;
		background-color: #000;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: 99999;
		visibility: hidden;
		opacity: 0;
		-webkit-transition: opacity 0.5s, visibility 0s 0.5s;
		transition: opacity 0.5s, visibility 0s 0.5s;
	}

	ul.member-actions {
		position: static;
	}

	.navicon {
		visibility: visible;
	}

	header .nav-wrapper {
		display: none;
	}

	header nav {
		position: relative;
		top: 45%;
		-moz-transform: translatey(-45%);
		-ms-transform: translatey(-45%);
		-o-transform: translatey(-45%);
		-webkit-transform: translatey(-45%);
		transform: translatey(-45%);
	}

	nav ul li a {
		color: #fff;
		font-size: 25px;
		text-transform: uppercase;
		font-weight: 600;
		-moz-transition: all 300ms ease-in-out;
		-o-transition: all 300ms ease-in-out;
		-webkit-transition: all 300ms ease-in-out;
		transition: all 300ms ease-in-out
	}

	nav ul li {
		margin-bottom: 25px
	}

	nav ul.primary-nav li {
		display: block;
	}

	nav ul.member-actions li {
		display: block;
	}

	nav a.login {
		margin-right: 0px;
	}

	nav ul.primary-nav {
		margin: 0;
		padding: 0;
		text-align: center;
	}

	nav ul.primary-nav li a {
		padding-right: 0;
	}

	nav .member-actions {
		position: static;
		padding: 0;
		text-align: center;
	}

	nav ul.primary-nav li,
	nav ul.member-actions li {
		-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
		filter: alpha(opacity=0);
		opacity: 0;
		-moz-transform: translate3d(0, -80px, 0);
		-ms-transform: translate3d(0, -80px, 0);
		-o-transform: translate3d(0, -80px, 0);
		-webkit-transform: translate3d(0, -80px, 0);
		transform: translate3d(0, -80px, 0);
		-moz-transition: -moz-transform 0.5s, opacity 0.5s;
		-o-transition: -o-transform 0.5s, opacity 0.5s;
		-webkit-transition: -webkit-transform 0.5s, opacity 0.5s;
		transition: transform 0.5s, opacity 0.5s;
		-moz-transition: -moz-transform 0.5s, opacity 0.5s;
		-o-transition: -o-transform 0.5s, opacity 0.5s;
		-webkit-transition: -webkit-transform 0.5s, opacity 0.5s;
		transition: transform 0.5s, opacity 0.5s
	}

	header .header-nav.open ul.primary-nav li,
	header .header-nav.open ul.member-actions li {
		-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
		filter: alpha(opacity=100);
		opacity: 1;
		-moz-transform: translate3d(0, 0, 0);
		-ms-transform: translate3d(0, 0, 0);
		-o-transform: translate3d(0, 0, 0);
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0)
	}

	nav ul.primary-nav li:first-child {
		-moz-transition-delay: 0.05s;
		-o-transition-delay: 0.05s;
		-webkit-transition-delay: 0.05s;
		transition-delay: 0.05s
	}

	nav ul.primary-nav li:nth-child(2) {
		-moz-transition-delay: 0.1s;
		-o-transition-delay: 0.1s;
		-webkit-transition-delay: 0.1s;
		transition-delay: 0.1s
	}

	nav ul.primary-nav li:nth-child(3) {
		-moz-transition-delay: 0.15s;
		-o-transition-delay: 0.15s;
		-webkit-transition-delay: 0.15s;
		transition-delay: 0.15s
	}

	nav ul.primary-nav li:nth-child(4) {
		-moz-transition-delay: 0.2s;
		-o-transition-delay: 0.2s;
		-webkit-transition-delay: 0.2s;
		transition-delay: 0.2s
	}

	nav ul.member-actions li:first-child {
		-moz-transition-delay: 0.25s;
		-o-transition-delay: 0.25s;
		-webkit-transition-delay: 0.25s;
		transition-delay: 0.25s
	}

	nav ul.member-actions li:nth-child(2) {
		-moz-transition-delay: 0.30s;
		-o-transition-delay: 0.30s;
		-webkit-transition-delay: 0.30s;
		transition-delay: 0.30s
	}

	.intro-content {
		display: inline-block;
		width: 75%;
		margin-bottom: 30px;
	}

	.last {
		margin: 0;
	}

	.features {
		padding-bottom: 0;
	}

	.features .device-showcase,
	.features-extra .macbook-wrap {
		display: none;
	}

	.features .responsive-feature-img,
	.features-extra .responsive-feature-img {
		display: block;
		width: 85%;
		margin: 15% auto 0;
	}

	.features .responsive-feature-img img,
	.features-extra .responsive-feature-img img {
		width: 100%;
	}

	.hero-strip {
		margin-top: 0;
	}

	.blog-intro .leftcol {
		padding: 0 15px;
		margin-bottom: 15px;
		border-right: none;
	}

	.blog-intro .rightcol {
		padding: 0 20px;
	}

	footer .social-share {
		display: block;
	}

	footer .social-share p {
		color: #fff;
		text-transform: lowercase !important;
		padding-bottom: 10px;
	}

	footer .footer-links {
		margin: 120px 0 120px 0;
	}
}

	@media screen and (max-width:640px) {
		footer ul.footer-group {
			border-top: none;
		}

		footer ul.footer-group li a {
			font-size: 13px;
			text-transform: uppercase;
			padding: 20px 0;
			display: block;
			border-bottom: dashed 1px #4E566C;
		}

		footer ul.footer-group li {
			display: block;
		}

		footer .footer-links {
			margin: 50px 0 50px 0;
		}

	}

	@media screen and (max-width:480px) {
		.hero .hero-content {
			padding-top: 41%;
		}

		.hero .btn {
			display: block;
			width: 80%;
			margin: 0 auto;
		}

		.hero .btn:first-of-type {
			margin-bottom: 20px;
		}

		.hero h1 {
			font-size: 50px;
		}

		.btn-margin-right {
			margin-right: 0px;
		}

		.features .device-showcase-left {
			position: absolute;
			bottom: -392px;
			left: 41%;
			right: auto;
			transform: translateX(-50%);
		}

		.features .ipad-wrap {
			width: 706px;
			height: 1002px;
			background: url("../img/ipad-device.png") no-repeat center center;
			z-index: 1;
			background-size: 40%;
		}

		.features .iphone-wrap {
			width: 304px;
			height: 617px;
			background: url("../img/iphone6.png") no-repeat center center;
			position: absolute;
			left: 350px;
			bottom: 129px;
			z-index: 2;
			background-size: 40%;
		}

		.features {
			padding-bottom: 0;
		}

		.down-arrow {
			display: none;
		}

	}

	@media screen and (max-width:320px) {
	}
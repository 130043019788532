@import url('https://fonts.googleapis.com/css2?family=Wallpoet&display=swap');

topbar {
    width: 100%;
    height: 50px;
    background-color: white;
    position: sticky;
    top: 0;
    z-index: 999999;
}

.topbarWrapper {
    height: 100%;
    padding: 0px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: rgb(239, 255, 222);
    background: linear-gradient(90deg, rgba(239, 255, 222, 0.9668242296918768) 10%, rgba(220, 252, 198, 1) 50%, rgba(67, 208, 93, 1) 75%, rgba(178, 229, 240, 1) 100%);
    max-height: 60px;
}

/*.logo {
    font-weight: bold;
    font-size: 30px;
    color: darkblue;
    cursor: pointer;


}*/

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {

    .logo_img {
        height: 10rem;
        padding-top: 10px;
        max-width: 10rem;
    }

    .logo_img:hover {
        background-color: transparent;
    }

}


.logo_img {
    height: 10rem;
    padding-top: 10px;
}

.logo_img:hover {
    background-color: transparent;
}

.topRight {
    display: flex;
    align-items: center;
}

.topbarIconsContainer {
    position: relative;
    cursor: pointer;
    margin-right: 10px;
    color: #555;
}

.topbarBadge {
    width: 15px;
    height: 15px;
    position: absolute;
    top: -5px;
    right: 0px;
    background-color: red;
    color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    font-weight: bolder;
}

.topAvatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
}

.logo img {
    vertical-align: middle;
    background-position: 50% 50%;
    width: 85%;
}

p.logo_text {
    margin: auto;
    font-size: 15px;
    font-family: "Wallpoet", sans-serif;
    margin-top: 20px;
    text-decoration: underline;
    text-decoration-color: chartreuse;
    color: black !important;
}